import { pt, en } from "./forms";

// prettier-ignore
export default {
  pt: {
    animation: "Animação",
    repeat: "Repetição",
    delay: "Atraso",
    speed: "Velocidade",
    standard: "padrão",
    infinite: "infinito",
    none: "nenhum",
    fast: "rápido",
    faster: "muito rápido",
    slow: "lento",
    slower: "muito lento",
    "delay-1s": "1s",
    "delay-2s": "2s",
    "delay-3s": "3s",
    "delay-4s": "4s",
    "repeat-1": "Repetir 1x",
    "repeat-2": "Repetir 2x",
    "repeat-3": "Repetir 3x",
    "blink": "blink (piscar)",
    "rotate": "rotate (rotacionar)"
  },
  en: {
    animation: "Animation",
    repeat: "Repeat",
    delay: "Delay",
    speed: "Speed",
    standard: "standard",
    infinite: "infinite",
    none: "none",
    fast: "fast",
    faster: "faster",
    slow: "slow",
    slower: "slower",
    "delay-1s": "1s",
    "delay-2s": "2s",
    "delay-3s": "3s",
    "delay-4s": "4s",
    "repeat-1": "Repeat 1x",
    "repeat-2": "Repeat 2x",
    "repeat-3": "Repeat 3x",
    "blink": "blink",
    "rotate": "rotate"
  },
};