<template>
  <div class="me">
    <div class="title" data-testid="title">
      {{ $t(title) }}
      <div class="unity" data-testid="unit">({{ unit }})</div>
      <span
        class="btn btn-xs"
        v-if="lockables && lockables.length > 0"
        v-on:click.prevent.stop="nextMode"
        data-testid="lock"
        :title="$t(modeTitle)"
      >
        <i :class="[modeIcon, modeColor]"></i>
      </span>
    </div>
    <div class="fields">
      <div class="field">
        <div
          :class="[
            'title',
            canLock(0) && locked ? modeColor : '',
            disabled[0] ? 'disabled' : ''
          ]"
          data-testid="field-label"
        >
          {{ $t(labels[0]) }}
        </div>
        <input
          type="number"
          ref="v0"
          :value="value[0]"
          @input="dispatch(0, $event.target.value)"
          :disabled="disabled[0]"
          @focus="editing = true"
          @blur="editing = false"
        />
      </div>
      <div class="field">
        <div
          :class="[
            'title',
            canLock(1) && locked ? modeColor : '',
            disabled[1] ? 'disabled' : ''
          ]"
          data-testid="field-label"
        >
          {{ $t(labels[1]) }}
        </div>
        <input
          type="number"
          ref="v1"
          :value="value[1]"
          :disabled="disabled[1]"
          @input="dispatch(1, $event.target.value)"
          @focus="editing = true"
          @blur="editing = false"
        />
      </div>
      <div class="field">
        <div
          :class="[
            'title',
            canLock(2) && locked ? modeColor : '',
            disabled[2] ? 'disabled' : ''
          ]"
          data-testid="field-label"
        >
          {{ $t(labels[2]) }}
        </div>
        <input
          type="number"
          ref="v2"
          :value="value[2]"
          :disabled="disabled[2]"
          @input="dispatch(2, $event.target.value)"
          @focus="editing = true"
          @blur="editing = false"
        />
      </div>
      <div class="field">
        <div
          :class="[
            'title',
            canLock(3) && locked ? modeColor : '',
            disabled[3] ? 'disabled' : ''
          ]"
          data-testid="field-label"
        >
          {{ $t(labels[3]) }}
        </div>
        <input
          type="number"
          ref="v3"
          :value="value[3]"
          :disabled="disabled[3]"
          @input="dispatch(3, $event.target.value)"
          @focus="editing = true"
          @blur="editing = false"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InlineDimensionForm",
  props: {
    title: {
      type: String,
      required: false,
      default: () => "dimensions"
    },
    unit: {
      type: String,
      required: false,
      default: () => "px"
    },
    labels: {
      type: Array,
      required: false,
      default: () => ["top", "bottom", "left", "right"]
    },
    value: {
      type: Array,
      required: false,
      default: () => [0, 0, 0, 0]
    },
    lockables: {
      type: Array,
      required: false,
      default: () => [] // index of the width or height
    },
    disabled: {
      type: Array,
      required: false,
      default: () => [false, false, false, false]
    }
  },
  data() {
    return {
      prop: 1,
      lock_mode: "idle", // idle | equal | prop,
      lock_modes: {
        idle: {
          icon: "fa fa-unlink",
          color: "",
          title: "unlinked"
        },
        prop: {
          icon: "fa fa-link",
          color: "text-prop",
          title: "proportional"
        },
        equal: {
          icon: "fa fa-square-o",
          color: "text-equal",
          title: "equal"
        }
      },
      editing: false
    };
  },
  computed: {
    lockModes() {
      if (this.lockables.length == 2) {
        return ["idle", "prop", "equal"];
      } else {
        return ["idle", "equal"];
      }
    },
    modeColor() {
      return this.lock_modes[this.lock_mode].color;
    },
    modeIcon() {
      return this.lock_modes[this.lock_mode].icon;
    },
    modeTitle() {
      return this.lock_modes[this.lock_mode].title;
    },
    locked() {
      return this.lock_mode !== "idle";
    }
  },
  watch: {
    value() {
      this.$nextTick(() => {
        if (this.editing) return;
        this.lock_mode = 'idle';
      });
    }
  },
  methods: {
    nextMode() {
      let i = this.lockModes.indexOf(this.lock_mode) + 1;
      if (i > this.lockModes.length - 1) {
        i = 0;
      }
      this.lock_mode = this.lockModes[i];
      let inp = this.$refs.v0;
      if (this.lock_mode == "prop") {
        this.prop =
          parseFloat(this.$refs.v2.value || 1) /
          parseFloat(this.$refs.v3.value || 1);
      }
      if (this.locked) {
        for (let i = 0; i <= 3; i++) {
          if (this.canLock(i)) {
            inp = this.$refs["v" + i];
            break;
          }
        }
      }
      this.$nextTick(() => {
        inp.focus();
      });
    },
    canLock(i) {
      return (this.lockables || []).indexOf(i) >= 0;
    },
    dispatch(i, value) {
      this.$emit("input", this.values(i, value));
    },
    values(i, value) {
      if (this.locked && this.canLock(i)) {
        return [
          parseInt(this.canLock(0) ? value : this.$refs.v0.value),
          parseInt(this.canLock(1) ? value : this.$refs.v1.value),
          parseInt(
            this.canLock(2)
              ? this.lock_mode == "equal" || i == 2
                ? value
                : this.$refs.v3.value * this.prop
              : this.$refs.v2.value
          ),
          parseInt(
            this.canLock(3)
              ? this.lock_mode == "equal" || i == 3
                ? value
                : this.$refs.v2.value / this.prop
              : this.$refs.v3.value
          )
        ];
      } else {
        return [
          parseInt(this.$refs.v0.value),
          parseInt(this.$refs.v1.value),
          parseInt(this.$refs.v2.value),
          parseInt(this.$refs.v3.value)
        ];
      }
    }
  }
};
</script>

<style scoped>
.me {
  display: block;
}

.me > .title {
  font-size: 10pt;
  color: #5e5e5e;
  font-weight: 600;
  margin-bottom: 10px;
}
.me > .title > .btn-xs {
  vertical-align: text-bottom;
}

.me > .title > .unity {
  display: inline-block;
  font-size: 8pt;
  vertical-align: top;
}

.me > .fields {
  padding: 0 1%;
}

.me > .fields > .field {
  display: inline-block;
  width: 24%;
  margin-right: 1%;
  position: relative;
}

.me > .fields > .field > .title {
  text-align: left;
  font-size: 8pt;
  position: absolute;
  top: -8px;
  left: 2px;
  background: white;
}

.me > .fields > .field > .disabled {
  background: transparent;
}

.me > .fields > .field > input {
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 3px;
  text-align: center;
  padding-top: 5px;
  font-size: 9pt;
}

.me > .fields > .field > input:focus {
  border-color: #3c8dbc;
  box-shadow: none;
  outline: 0;
}

input[type="number"] {
  opacity: 1;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}

input[disabled] {
  cursor: not-allowed;
  background-color: #eee;
  opacity: 1;
}

.text-prop {
  color: orangered;
  text-shadow: 1px 1px 2px grey;
}

.text-equal {
  color: #3c8dbc;
  text-shadow: 1px 1px 2px grey;
}
</style>
