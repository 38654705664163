<template>
  <div class="me">
    <div class="text-center">
      <span
        v-if="isDefault"
        class="btn btn-xs"
        :disabled="!can || busy"
        :title="
          $t(can ? 'reset' : 'you_do_not_have_access_to_the_requested_resource')
        "
        @click.stop.prevent="updateDefault('resetDefault')"
      >
        <span class="text-primary text-label">
          <i class="fa fa-check-square-o"></i>
          {{ $t("this_panel_is_set_as_default") }}
        </span>
        <span class="btn btn-xs">
          <i class="fa fa-refresh fa-spin" v-if="busy"></i>
          <i class="fa fa-undo" v-else></i>
        </span>
      </span>
      <span
        v-else
        class="btn btn-xs"
        :disabled="!can || busy"
        :title="
          $t(
            can
              ? 'set_as_default'
              : 'you_do_not_have_access_to_the_requested_resource'
          )
        "
        @click.stop.prevent="updateDefault('setAsDefault')"
      >
        <span class="text-label">
          <i class="fa fa-refresh fa-spin" v-if="busy"></i>
          {{ $t("set_as_default") }}
        </span>
        <i class="fa fa-square-o"></i>
      </span>
      <ToolTip
        v-if="tooltip"
        :title="$t(tooltip)"
        position="bottom"
        style="vertical-align: middle;margin-left: 15px;"
      />
    </div>
  </div>
</template>

<script>
import ToolTip from "@/components/tooltip.vue";
import ContractService from "@/services/contract.js";
export default {
  name: "PanelContractBinder",
  components: {
    ToolTip
  },
  props: {
    tooltip: {
      type: String,
      required: false,
      default: "hints.device_table_list_standard_panel"
    },
    homePanelName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      busy: false
    };
  },
  computed: {
    can() {
      return this.$can("manage", "ContratoEscrita");
    },
    contract() {
      return this.$store.getters["user/contract"] || {};
    },
    panel() {
      return this.$store.getters["dashboard/currentDraftPanel"] || null;
    },
    screenId() {
      return (this.$store.getters["dashboard/draft"] || { screenId: "" })
        .screenId;
    },
    panelOptions() {
      return this?.panel?.options || null;
    },
    screenPath() {
      return (
        (this.$store.getters["dashboard/screen"](this.screenId) || {})?.path ||
        null
      );
    },
    isDefault() {
      return (
        ((this?.contract?.portal_data?.search_page || {})[this.homePanelName]
          ?.path || "") == this.screenPath
      );
    }
  },
  watch: {
    isDefault() {
      if (this.busy) {
        this.busy = false;
      }
    }
  },
  methods: {
    savePortalData(payload) {
      this.busy = true;
      this.srv.updateContract(payload).then(
        (response) => {
          this.busy = false;
          if (response && typeof response == "object" && response.id) {
            this.$store.dispatch("user/configureUserContract");
          } else {
            this.$swal({
              title: this.$t("item_could_not_be_saved"),
              text: this.$t("invalid_data"),
              icon: "error"
            });
          }
        },
        (error) => {
          this.busy = false;
          this.$swal({
            title: this.$t("item_could_not_be_saved"),
            text: `${error?.status || ""} ${error?.statusText ||
              this.$t("invalid_data")}`,
            icon: "error"
          });
        }
      );
    },
    updateDefault(option) {
      if (!this.can || !this.screenPath || this.busy) return;
      let payload = {
        portal_data: JSON.parse(
          JSON.stringify(this?.contract?.portal_data || {})
        ),
        etag: this?.contract?.etag || "",
        id: this?.contract?.id || ""
      };
      if (!payload.id || !payload.etag) return null;
      if (!payload) return;
      payload.portal_data.search_page = payload.portal_data.search_page || {};
      if (option == "setAsDefault") {
        payload.portal_data.search_page[this.homePanelName] = {
          screen_id: this.screenId,
          path: this.screenPath,
          panel_name: this.panel.name
        };
        this.savePortalData(payload);
      } else if (option == "resetDefault") {
        delete payload.portal_data.search_page[this.homePanelName];
        this.savePortalData(payload);
      }
    }
  },
  created() {
    this.srv = new ContractService();
  }
};
</script>

<style scoped>
.me {
  background-color: whitesmoke;
  padding: 0 5px;
}

.me > div {
  padding: 5px;
  text-align: center;
  border-width: 1px 0;
  border-color: rgb(189, 189, 189);
  border-style: solid;
  font-size: 10pt;
  white-space: nowrap;
}

span.text-label {
  padding: 0 10px;
}
</style>
