<template>
    <a href="javascript:void(0)">
        <i 
        v-if="dataDisplayLabel == 'name'"
        class="fa fa-bookmark-o btn btn-xs bg-default"
        @click.stop.prevent="dataDisplayLabel = 'identity_embedded_app'"
        :title="$t('displaying_data_as')+' '+$t('data_name')+'. '+$t('click_to_change_to')+': '+$t('identity_embedded_app')"
        ></i>
        <i
        v-else
        class="fa fa-tag btn btn-xs bg-default"
        @click.stop.prevent="dataDisplayLabel = 'name'"
        :title="$t('displaying_data_as')+' '+$t('identity_embedded_app')+'. '+$t('click_to_change_to')+': '+$t('data_name')"
        ></i>
    </a>
</template>

<script>
export default {
    name: "DataDisplayLabelToggle",
    computed: {
        dataDisplayLabel: {
            set(value) {
                this.$store.dispatch("dashboard/setDataDisplayLabel", value);
            },
            get() {
                return this.$store.getters["dashboard/dataDisplayLabel"];
            }
        }
    }
}
</script>