<template>
  <div style="position: relative; clear: both" :title="value">
    <slot name="button" v-bind:toggle="toggle" v-if="buttonAttrs">
      <button
        class="btn btn-default"
        :class="$attrs.sm ? 'btn-sm' : $attrs.xs ? 'btn-xs' : ''"
        @click="toggle"
        data-testid="toggle"
        v-bind="buttonAttrs"
      >
        <span :class="value" data-testid="toggle-icon"></span>
      </button>
    </slot>
    <portal to="contextmenu">
      <FloatPanel
        class="float-panel"
        :style="style"
        :draggable="true"
        :handleSelf="true"
        :open="floatPanel.open"
        :defaultPosition="floatPanel.rect"
        :title="$tc('icon', 2)"
        @update:open="!$event && resetPanelState()"
        @dragstart="floatPanel.dragging = true"
        @dragend="onDragEnd(floatPanel, $event)"
      >
        <div class="float-panel-content no-select">
          <IconSelector v-model="icon" ref="selector" v-if="floatPanel.open" />
        </div>
      </FloatPanel>
    </portal>
  </div>
</template>

<script>
import FloatPanel from "./float-panel";
import IconSelector from "@/components/editor/icon-selector.vue";

const _fpsize = [600, 500];
let _fp = {
  w: _fpsize[0],
  h: _fpsize[1],
  x: parseInt((window.innerWidth - _fpsize[0]) / 2),
  y: parseInt((window.innerHeight - _fpsize[1]) / 2)
};
_fp.y = _fp.y < window.innerHeight ? _fp.y : parseInt(window.innerHeight * 0.8);
_fp.x = _fp.x < window.innerWidth ? _fp.x : parseInt(window.innerWidth * 0.8);
window.floatPanelZIndex = 1000;
export default {
  name: "IconLibrary",
  props: {
    buttonAttrs: {
      type: Object,
      required: false,
      default: () => ({})
    },
    loadAmount: {
      type: Number,
      required: false,
      default: 9
    },
    value: {
      type: String,
      required: false,
      default: "glyphicon glyphicon-th-large"
    },
    panelOpen: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    FloatPanel,
    IconSelector
  },
  data() {
    return {
      floatPanel: {
        rect: {
          top: `${_fp.y}px`,
          left: `${_fp.x}px`
        },
        open: false,
        dragging: false
      },
      zIndex: window.floatPanelZIndex
    };
  },
  computed: {
    style() {
      return {
        "--z-index": this.zIndex
      };
    },
    icon: {
      set(value) {
        this.floatPanel.open = false;
        this.$emit("input", value);
      },
      get() {
        return this.value || "glyphicon glyphicon-th-large";
      }
    }
  },
  watch: {
    panelOpen: {
      immediate: true,
      handler(val) {
        this.floatPanel.open = val;
      }
    },
    "floatPanel.open"(val) {
      if (val != this.panelOpen) {
        this.$emit("update:panelOpen", val);
      }
      if (val) {
        this.highlight();
      } else {
        this.reset();
      }
    }
  },
  methods: {
    highlight() {
      this.$nextTick(() => {
        if (this.$refs.selector) {
          this.$refs.selector.highlight();
        }
      });
    },
    toggle() {
      this.floatPanel.open = !this.floatPanel.open;
      this.highlight();
    },
    reset() {
      if (this.$refs.selector) {
        this.$refs.selector.reset();
      }
    },
    resetPanelState() {
      this.floatPanel.open = false;
    },
    onDragEnd(floatPanel, $event) {
      floatPanel.top = $event.top;
      floatPanel.left = $event.left;
      floatPanel.dragging = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.float-panel {
  padding: 0;
  clear: both;
  z-index: var(--z-index);
  .float-panel-content {
    width: 400px;
    min-height: 320px;
    position: relative;
    resize: horizontal;
    overflow: hidden auto;
    margin-top: 30px;
  }
}
</style>
