<template>
  <div>
    <div class="form-group">
      <label for="">{{ $t("titles.remote_panel") }}</label>
      <div
        class="text-center"
        style="
          margin-top: 10px;
          background-color: whitesmoke;
          border-radius: 5px;
          padding: 10px;
        "
      >
        {{ $t("titles.editing_not_allowed") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LinkedPanelWarning"
}
</script>
