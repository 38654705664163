<template>
  <section v-if="ready">
    <div class="preview">
      <div class="text-center" :class="{ disabled: !entry.animation }">
        <div v-if="!switching" :class="classList">
          <i class="fa fa-bell sample"></i>
        </div>
      </div>
      <div>
        <div
          :title="$t('reset')"
          class="btn btn-sm btn-default"
          @click.stop.prevent="parseValue"
        >
          <i class="fa fa-undo"></i>
        </div>
        <div
          :title="$t('clear')"
          class="btn btn-sm btn-default"
          @click.stop.prevent="reset"
          style="margin-left: 10px"
        >
          <i class="fa fa-trash-o"></i>
        </div>
      </div>
    </div>
    <div>
      <div
        class="form-group form-group-sm"
        :key="p"
        v-for="p in ['animation', 'repeat', 'delay', 'speed']"
      >
        <div class="input-group">
          <div
            class="input-group-addon lbl btn"
            @dblclick.stop.prevent="entry[p] = ''"
            title="double click to reset it"
          >
            {{ $t(p) }}
          </div>
          <select
            class="form-control"
            v-model="entry[p]"
            :disabled="p !== 'animation' && !entry.animation"
          >
            <option value="" style="">
              {{ $tc(p == "animation" ? "none" : "standard", 1) }}
            </option>
            <option :value="i" v-for="i in references[p]" :key="i">
              {{ entry.animation || p == "animation" ? $t(i) : $t("standard") }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import messages from "@/i18n/animation.js";
const Animation = () => ({
  animation: "",
  repeat: "",
  delay: "",
  speed: ""
});

export default {
  name: "AnimationForm",
  i18n: { messages },
  props: {
    value: {
      type: String,
      default: "",
      required: false
    }
  },
  data() {
    return {
      ready: false,
      entry: Animation(),
      switching: false
    };
  },
  computed: {
    references() {
      return this.ready ? this._references : null;
    },
    classList() {
      return this.entry.animation
        ? ["animated"]
          .concat(Object.values(this.entry).filter((i) => i !== ""))
          .map((i) => `a-${i}`)
        : [""];
    },
    isDirty() {
      let olst = (this.value || "").split(".");
      return [...this.classList].filter((n) => olst.indexOf(n) < 0).length > 0;
    }
  },
  watch: {
    classList(n) {
      this.switching = true;
      this.$nextTick(() => (this.switching = false));
      this.apply();
    }
  },
  methods: {
    classify(selector) {
      if (selector && /^\.a\-/.test(selector || "")) {
        if (/^.a\-animated/.test(selector)) {
          selector = selector.split(".")[2];
          if (/(-repeat|-infinite)/.test(selector))
            return { type: "repeat", value: selector.substr(2) };
          else if (/(-delay)/.test(selector))
            return { type: "delay", value: selector.substr(2) };
          else if (/(-fast|-slow)/.test(selector))
            return { type: "speed", value: selector.substr(2) };
        } else {
          return { type: "animation", value: selector.split(".")[1].substr(2) };
        }
      }
      return null;
    },
    parseStyleSheet() {
      let ss = null;
      for (const sheet of document.styleSheets) {
        if (/animate\.min\.css$/.test(sheet.href)) {
          ss = sheet;
          break;
        }
      }
      if (!ss) return;
      let info = null,
        entry = Animation();
      for (var p in entry) entry[p] = [];
      for (const rule of ss.cssRules) {
        // let selector = rule.selectorText;
        info = this.classify(rule.selectorText);
        if (!info) continue;
        entry[info.type].push(info.value);
      }
      for (var p in entry) entry[p] = entry[p].sort();
      this._references = entry;
      this.ready = true;
    },
    parseValue() {
      if (!this.original) return;
      let lst = (this.original || "").split(".").map((i) => i.substr(2));
      let vlr = "";
      lst.forEach((cls) => {
        for (var p in this.entry) {
          vlr = this._references[p].find((i) => cls == i);
          if (vlr) {
            this.entry[p] = vlr;
            break;
          }
        }
      });
    },
    reset() {
      this.$set(this, "entry", Animation());
      this.apply();
    },
    apply() {
      this.$emit("input", this.classList.join("."));
    }
  },
  created() {
    this.parseStyleSheet();
  },
  mounted() {
    this.original = this.value;
    this.parseValue();
  }
};
</script>

<style scoped>
.input-group-addon.lbl {
  min-width: 90px;
}

.form-group-sm {
  margin-bottom: -1px;
}

.preview {
  border: 1px solid #d2d6de;
  border-radius: 5px 5px 0 0;
  margin-bottom: -1px;
  text-align: center;
}

.preview > div:first-child {
  overflow: hidden;
  padding: 50px;
  opacity: 1;
  display: inline-block;
  background: whitesmoke;
  margin-top: 20px;
  border-radius: 50%;
  border: 1px solid darkgray;
}

.preview > div:first-child.disabled {
  opacity: 0.5;
}

.preview > div:last-child {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.preview > div:last-child::after {
  clear: both;
}

.sample {
  font-size: 32pt;
  color: orangered;
  text-shadow: 4px 4px 4px gray;
}
</style>
