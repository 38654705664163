<template>
  <DetailForm persistent="toggle_device_panel">
    <DetailFormDeviceList />
  </DetailForm>
</template>

<script>
import DetailForm from "@/components/control-sidebar/property-editors/detail-form.vue";
import DetailFormDeviceList from "@/components/control-sidebar/property-editors/detail-form-device-list.vue";
export default {
  name: "DeviceListTableForm",
  components: {
    DetailForm,
    DetailFormDeviceList
  }
};
</script>
