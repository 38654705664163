<template>
  <div v-if="ready">
    <InlineDimensionForm
      v-bind:labels="labels"
      v-bind:lockables="[2, 3]"
      v-model="dimensions"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import InlineDimensionForm from "./inline-dimension-form.vue";
export default {
  name: "ControlRectProperties",
  components: {
    InlineDimensionForm
  },
  props: {
    control: {
      type: Object,
      required: true
    }
  },
  computed: {
    ready() {
      return this.control &&
        this.control.synopticComponent &&
        this.control.synopticComponent.clientRect
        ? true
        : false;
    },
    dimensions: {
      set(rect) {
        this.updateControl({
          id: this.control.id,
          control: {
            ...this.control,
            synopticComponent: {
              ...this.control.synopticComponent,
              clientRect: {
                top: rect[0],
                left: rect[1],
                width: rect[2],
                height: rect[3]
              }
            }
          }
        });
      },
      get() {
        if (this.ready) {
          return [
            this.control.synopticComponent.clientRect["top"],
            this.control.synopticComponent.clientRect["left"],
            this.control.synopticComponent.clientRect["width"],
            this.control.synopticComponent.clientRect["height"]
          ];
        } else {
          return [0, 0, 0, 0];
        }
      }
    },
    labels() {
      return (this?.control?.synopticComponent?.rotation == 270 || this?.control?.synopticComponent?.rotation == 90) ?
        ['top', 'left', 'height', 'width'] : ['top', 'left', 'width', 'height']
    }
  },
  methods: {
    ...mapActions("synoptic", ["updateControl"])
  }
};
</script>

<style scoped></style>
