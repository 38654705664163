<template>
  <div v-if="level == -1" class="tree-root">
    <TreeView
      v-for="child in node"
      :key="child.id"
      :node="child"
      :level="level + 1"
      :icons="icons"
    />
  </div>
  <div v-else :style="indentOffSetClass" class="tree-branch">
    <div class="clicable tree-item no-select" @click.stop.prevent="toggle">
      <span :class="textClass" :title="node.label">
        <i :class="icon" />
        {{ node.label }}
        <!-- debug only -->
        <!-- [level: {{ level }}] -->
      </span>
    </div>
    <div v-if="children && !collapsed">
      <TreeView
        v-for="child in node.children"
        :key="child.id"
        :node="child"
        :level="level + 1"
        :icons="icons"
      />
    </div>
  </div>
</template>

<script>
// recursive tree node view
// marco@zoqui.com
/*
recursive tree node view
marco@zoqui.com

usage: 

const nodes=
[
  {
    id: "1",
    label: "level 1",
    children: [
      {
        id: "1.1",
        label: "level 1.1",
        children: [
          {
            id: "1.1.1",
            label: "level 1.1.1",
            children: [
              {
                id: "1.1.1.1",
                label: "level 1.1.1.1"
              },
              ...
            ]
          },
          ...
        ]
      }
    ]
  },
  ...
]

<TreeView
  :node="nodes"
  :icons="[
    {
      open: 'fa fa-folder-open-o',
      close: 'fa fa-folder-o',
      leaf: 'fa fa-file-o'
    },
    {
      open: 'fa fa-minus',
      close: 'fa fa-plus',
      leaf: 'fa fa-file-excel-o'
    },
    {
      open: 'fa fa-chevron-down',
      close: 'fa fa-chevron-right',
      leaf: 'fa fa-file-pdf-o'
    }
  ]"
  @click="log($event)"
/>

*/
const ICONS = {
  open: "fa fa-folder-open-o",
  close: "fa fa-folder-o",
  leaf: "fa fa-file-o",
  leafUnselected: "fa fa-file-o",
  leafSelected: "fa fa-file-o"
};

const CLASSES = {
  open: "text-primary text-bold",
  close: ""
};

export default {
  name: "TreeView",
  props: {
    node: {
      type: [Object, Array],
      required: true
    },
    level: {
      type: Number,
      default: -1
    },
    indent: {
      type: Number,
      default: 20
    },
    icons: {
      type: Array,
      required: false,
      default: () => [ICONS] // each level might be configured with a different set of icons
    },
    classes: {
      type: Array,
      required: false,
      default: () => [CLASSES] // each level might be configured with a different set of icons
    }
  },
  data: () => ({
    collapsed: true,
    nodes: null
  }),
  computed: {
    children() {
      return this?.node?.children?.length ? this?.node?.children || [] : null;
    },
    indentOffSetClass() {
      return {
        "padding-left": `${this.level ? this.indent : 0}px`
      };
    },
    icon() {
      let icons =
        (this.icons.length &&
          this.level < this.icons.length &&
          this.icons[this.level]) ||
        ICONS;
      return this.children
        ? this.collapsed
          ? icons.close || ICONS.close
          : icons.open || ICONS.open
        : this.collapsed
          ? icons.leafUnselected ||
          ICONS.leafUnselected ||
          icons.leaf ||
          ICONS.leaf
          : icons.leafSelected || ICONS.leafSelected || icons.leaf || ICONS.leaf;
    },
    textClass() {
      let classes =
        (this.classes.length &&
          this.level < this.classes.length &&
          this.classes[this.level]) ||
        CLASSES;
      return this.collapsed
        ? classes.close || CLASSES.close
        : classes.open || CLASSES.open;
    }
  },
  methods: {
    close(node) {
      node.collapsed = true;
      if (node.children) {
        for (var i = 0; i < node.children.length; i++) {
          this.close(node.children[i]);
        }
      }
    },
    toggle() {
      this.collapsed = !this.collapsed;
      if (this.collapsed) {
        this.close(this.node);
      } else {
        this.node.collapsed = false;
      }
      this.notifyParent();
    },
    notifyParent() {
      const $e = {
        node: this.node,
        action: this.node.collapsed ? "nodeClose" : "nodeOpen"
      };
      const doNext = ($node) => {
        if ($node.$parent.notifyParent) {
          doNext($node.$parent);
        } else {
          $node.$emit("click", $e);
        }
      };
      doNext(this.$parent);
    }
  },
  created() {
    if (this.level > -1) {
      this.node.collapsed = this.node.collapsed ?? true;
      this.collapsed = this.node.collapsed;
      if (this?.node?.id === undefined) {
        this.node.id = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(
          /[018]/g,
          (c) =>
            (
              c ^
              (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
            ).toString(16)
        );
      }
      // } else {
      //   console.log("i");
    }
  }
};
</script>

<style scoped>
.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}
.tree-root {
  width: 100%;
  /* white-space: nowrap; */
}

.tree-item {
  margin: 5px 0;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.no-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
</style>
