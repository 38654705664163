<template>
  <div
    class="box toggle-panel"
    ref="me"
    v-if="layout == 'box'"
    :data-dbkey="persistent || ''"
  >
    <div
      class="box-header with-border clicable no-select"
      @click.stop.prevent="toggle"
    >
      <div class="h4 box-title" data-testid="toggle-panel-btn">
        <i :class="[icon.before, 'icon-before']" v-if="icon.before"></i>
        <slot name="title">
          {{ $t(title) }}
          <ToolTip v-if="hint" :title="$t(hint)" />
        </slot>
        <div
          class="pull-right"
          style="padding: 0 5px; display: inline-block; width: 20px"
        >
          <i class="fa fa-angle-right" v-if="iCollapsed"></i>
          <i class="fa fa-angle-down" v-else></i>
        </div>
      </div>
      <div class="box-tools pull-right">
        <slot name="toolbar"></slot>
        <button class="btn btn-box-tool" data-widget="collapse">
          <i class="fa" :class="icon.collapse"></i>
        </button>
      </div>
    </div>
    <div class="box-body fade-in"><slot></slot></div>
  </div>
  <div ref="me" v-else-if="layout == 'simple'" class="simple toggle-panel">
    <label class="clicable" @click.stop.prevent="iCollapsed = !iCollapsed"
      >{{ $t(title) }}
      <i :class="iCollapsed ? 'fa fa-chevron-down' : 'fa fa-chevron-up'"></i>
    </label>
    <div v-if="!iCollapsed"><slot></slot></div>
  </div>
  <div
    ref="me"
    v-else-if="layout == 'compressed'"
    class="compressed toggle-panel"
  >
    <div class="clicable" @click.stop.prevent="toggle">
      <div class="compressed-title" :class="!iCollapsed ? 'text-primary' : ''">
        <div>{{ $t(title) }}</div>
        <div class="compressed-icon">
          <i :class="iCollapsed ? 'fa fa-angle-right' : 'fa fa-angle-down'"></i>
        </div>
      </div>
    </div>
    <div
      class="fade-in compressed-body"
      :class="iCollapsed ? 'compressed-body-closed' : 'compressed-body-opened'"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
const CNAME = () => "TogglePanel";
import ToolTip from "@/components/tooltip.vue";
export default {
  name: CNAME(),
  components: {
    ToolTip
  },
  props: {
    layout: {
      type: String,
      default: "box",
      required: false
    },
    collapsed: {
      // original option (requested from parent)
      type: Boolean,
      default: true,
      required: false
    },
    title: {
      type: String,
      default: "Untitled",
      required: false
    },
    icon: {
      type: Object,
      default: () => {
        return { collapse: "fa-minus", expand: "fa-plus" };
      },
      required: false
    },
    persistent: {
      type: String,
      default: () => CNAME(),
      required: false
    },
    hint: {
      type: String,
      default: "",
      required: false
    },
    accordion: {
      type: String,
      default: "",
      required: false
    }
  },
  data() {
    return {
      iCollapsed: true,
      restoring: false
    };
  },
  computed: {
    dbState() {
      return this.persistent == this.$options.name
        ? this.title
        : this.persistent;
    }
  },
  methods: {
    toggle() {
      let $grp = null;
      if (this.accordion && this.iCollapsed) {
        $grp = document.querySelectorAll(`div.toggle-panel[data-accordion="${this.accordion}"]`);
      }
      if (this.layout == 'simple' || this.layout == 'compressed') {
        if ($grp) {
          let classes = null;
          $grp.forEach((el) => {
            if (el.querySelector('.compressed-body').classList.contains('compressed-body-opened')) {
              el.__vue__.toggle();
            }
          })
        }
        this.iCollapsed = !this.iCollapsed;
      }
      else {
        if ($grp) $($grp).boxWidget("collapse");
        $(this.$refs.me).boxWidget("toggle");
      }
    },
    restore() {
      this.restoring = true;
      if (this.persistent) {
        let editorSettings = this.$store.getters["dashboard/editorSettings"];
        let state = editorSettings.togglePanelState || {};
        if (this.persistent in state) {
          this.iCollapsed = state[this.dbState];
        } else {
          this.iCollapsed = this.collapsed;
        }
      } else {
        this.iCollapsed = this.collapsed;
      }
    },
    async save() {
      if (this.persistent && this._isMounted) {
        this.$store.dispatch("dashboard/togglePanel", {
          name: this.dbState,
          state: this.iCollapsed
        });
      }
    }
  },
  watch: {
    iCollapsed(n) {
      if (this.iCollapsed != this.collapsed) {

        this.$emit("changed", n);
      }
      if (!this.restoring) this.save();
    },
    collapsed(n) {
      if (n != this.iCollapsed) {
        this.iCollapsed = n;
        // console.log(`${this.persistent} ${this.iCollapsed} - via prop`);
        if (this.$refs.me) {
          $(this.$refs.me).boxWidget(this.iCollapsed ? "collapse" : "expand");
        }
      }
    }
  },
  created() {
    this.restore();
  },
  mounted() {
    let self = this;
    this.restoring = false;
    if (this.accordion) {
      this.$refs.me.dataset["accordion"] = this.accordion;
    }
    if (this.layout == "box") {
      let $el = $(self.$refs.me)
        .boxWidget({
          animationSpeed: this.accordion ? 300 : 200,
          collapseIcon: self.icon.collapse || "fa-minus",
          expandIcon: self.icon.expand || "fa-pluss"
        })
        .on("collapsed.boxwidget", (e) => {
          e.stopPropagation();
          self.iCollapsed = true;
          self.$emit("close");
        })
        .on("expanded.boxwidget", (e) => {
          e.stopPropagation();
          self.iCollapsed = false;
          self.$emit("open");
        });
      $el.boxWidget(self.iCollapsed ? "collapse" : "expand");
    }
  }
};
</script>

<style scoped>
.box {
  border: none;
  box-shadow: none;
  margin-bottom: 0;
}

.box-title {
  font-size: 12pt;
  margin: 0;
  width: 100%;
}

.box-body {
  border: 0;
  border-radius: 0;
  padding: 0;
  margin-bottom: 10px;
}
.collapsed-box:not(:last-child) {
  margin-bottom: 12px;
}

.box-header {
  padding: 6px 2px 6px 8px;
  position: relative;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #f4f4f4;
}

.box-header > .box-tools {
  top: 0;
  right: 0;
}

.box-header > .box-tools > .btn-box-tool {
  color: transparent;
}

.clicable:hover {
  cursor: pointer;
  background-color: #eeea;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.simple > div {
  padding: 8px 10px 4px 10px;
  border: 1px solid lightgray;
}

.icon-before {
  margin-right: 2px;
  font-size: 1.4rem;
  width: 1.4rem;
  text-align: center;
}

.compressed {
  position: relative;
  margin: 10px 0;
}
.compressed-title {
  padding-left: 5px;
}
.compressed-title.text-primary {
  font-weight: 600;
}
.compressed-title:hover {
  background-color: whitesmoke;
}

.compressed-icon {
  position: absolute;
  right: 5px;
  top: 0;
}

.compressed-body {
  clear: both;
  overflow-y: hidden;
  height: auto;
  padding: 5px 5px 10px 5px;
}

.compressed-body-closed {
  max-height: 0;
  padding: 0;
}

.compressed-body-opened {
  max-height: 500px;
  transition: max-height 100ms ease-in;
}
</style>
<style>
.toggle-panel.has-sub-panel .toggle-panel {
  padding-left: 1em;
}

.tab-content .toggle-panel .box-header {
  margin-inline: -15px;
}
</style>
