<template>
  <div style="clear:both">
    <div style="clear:both">
      <div class="pull-left">
        <b> {{ $tc("group", list.length > 1 ? 2 : 1) }}: </b>
      </div>
      <div class="toolbar pull-right">
        <span
          class="clicable"
          :title="$t('reset')"
          @click.stop.prevent="$emit('reset')"
          ><i class="fa fa-undo"></i
        ></span>
      </div>
    </div>
    <div class="list">
      <template v-for="(item, ix) in list">
        <div :key="ix" class="list-item">
          <div class="group clicable" @click.stop.prevent="toggle(item)">
            <div class="pull-left">
              <span
                class=" title"
                :class="{
                  'text-disabled': !expanded[item.id] && !item.screenId,
                  'text-primary text-bold': expanded[item.id]
                }"
              >
                <i
                  :class="item.screenId ? 'fa fa-desktop' : 'fa fa-television'"
                ></i>
                {{ item.id }}
              </span>
            </div>
            <div class="toolbar pull-right">
              <span class="clicable" @click.stop.prevent="toggle(item)"
                ><i
                  :class="
                    expanded[item.id]
                      ? 'fa fa-chevron-up'
                      : 'fa fa-chevron-down'
                  "
                ></i
              ></span>
            </div>
          </div>
          <ScreenSelector
            class="screen"
            v-if="expanded[item.id]"
            :value="screenId(item.id)"
            @input="screenId(item.id, $event)"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { isEqual } from "lodash";
import ScreenSelector from "@/components/editor/screen-selector.vue";

export default {
  name: "GroupScreenMapForm",
  components: {
    ScreenSelector
  },
  props: {
    value: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data: () => ({
    items: [],
    expanded: {}
  }),
  computed: {
    list() {
      return JSON.parse(JSON.stringify(this.items || [])).sort((a, b) =>
        a.id > b.id ? 1 : b.id > a.id ? -1 : 0
      );
    }
  },
  watch: {
    value: {
      handler(n) {
        if (!n || isEqual(n, this.items)) return;
        this.$set(this, "items", JSON.parse(JSON.stringify(n)));
      },
      immediate: true,
      deep: true
    },
    items: {
      handler(n) {
        if (!n || isEqual(n, this.value)) return;
        this.$emit("input", n);
      },
      deep: true
    }
  },
  methods: {
    level(id) {
      return id.split(".").length;
    },
    title(id) {
      return id.split(".").pop();
    },
    toggle(item) {
      this.$set(this.expanded, item.id, this.expanded[item.id] ? false : true);
    },
    screenId(id, screenId) {
      if (id) {
        let item = this.items.find((i) => i.id == id);
        if (item) {
          if (screenId !== undefined) {
            this.$set(item, "screenId", screenId);
          }
          return item.screenId;
        }
      }
      return "";
    }
  },
  mounted() {}
};
</script>

<style scoped>
.toolbar > span {
  margin: 0 5px;
}

.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}

.list {
  max-height: 300px;
  overflow-y: inherit;
  width: 100%;
}

.list-item > div.group {
  clear: both;
  padding: 8px 0px 8px 0px;
  overflow: hidden;
}

.list-item > div.group:hover {
  background-color: whitesmoke;
}

.list-item > .screen {
  border-top: 1px solid #d2d2d2;
  padding: 4px 8px 8px 8px;
  margin-top: 0;
  background-color: whitesmoke;
  border-radius: 0 0 5px 5px;
}
.text-disabled {
  color: #999;
}
</style>
