<template>
  <div class="form-group form-group-sm limit-box">
    <slot name="label">
      <label v-if="label">{{ $t(label) }}</label>
    </slot>
    <div
      class="input-group"
      :style="{ width: iType == 'data' ? '100%' : 'inherit' }"
    >
      <div
        class="input-group-addon label-addon no-padding"
        :class="{ disabled: $attrs.disabled }"
      >
        <span class="text-bold"
          >&nbsp;&nbsp;{{ $t("source") }}&nbsp;&nbsp;</span
        >
      </div>
      <select
        class="form-control form-group-sm no-padding"
        v-model="iType"
        :disabled="$attrs.disabled"
      >
        <option value="constant">{{ $t("constant") }}</option>
        <option value="data">{{ $t("from_data") }}</option>
      </select>
      <div class="input-group-addon no-padding" v-if="iType == 'constant'">
        <div class="constants">
          <div class="labels">
            <span>{{ $t("titles.index") }}</span>
          </div>
          <input
            class="form-control no-padding index"
            type="number"
            :placeholder="parseInt(iValue) < 0 ? 'N/A' : '0'"
            :disabled="$attrs.disabled"
            :value="parseInt(iValue) < 0 ? '' : iValue"
            @input="iValue = $event.target.value"
          />
        </div>
      </div>
      <div class="input-group-addon no-padding" v-else-if="offset">
        <div class="constants">
          <div class="labels">
            <span v-if="offset">{{ $t("expression") }}/offset</span>
          </div>
          <input
            v-if="offset"
            class="form-control no-padding"
            type="text"
            :placeholder="`${$t('offset')}`"
            :disabled="$attrs.disabled"
            v-model="iOffSet"
          />
        </div>
      </div>
    </div>
    <ControlDataSelector
      v-if="iType == 'data' && !$attrs.disabled"
      label=""
      :addon="$tc('data')"
      :expressionToolbar="false"
      :connectorId="$attrs.connectorId || 0"
      :exclude="$attrs.exclude || []"
      :value="iDataId"
      :disabled="$attrs.disabled"
      @input="iDataId = $event"
    />
  </div>
</template>

<script>
import ControlDataSelector from "@/components/synoptic/property-editor/controls/control-data-selector.vue";

const initialValue = () => ({
  type: "constant",
  data_id: "",
  value: 0,
  offset: 0,
  enabled: true
});

export { initialValue };

export default {
  name: "ValueSourceSelector",
  components: {
    ControlDataSelector
  },
  props: {
    label: {
      type: String,
      default: "value"
    },
    value: {
      type: Object,
      default: initialValue(),
      required: false
    },
    offset: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    iType: {
      set(val) {
        let entry = { ...this.value };
        entry.type = val;
        this.$emit("input", entry);
      },
      get() {
        return this.value.type;
      }
    },
    iDataId: {
      set(val) {
        if (!val) return;
        let entry = { ...this.value };
        entry.data_id = val;
        this.$emit("input", entry);
      },
      get() {
        return this.value.data_id;
      }
    },
    iValue: {
      set(val) {
        let entry = { ...this.value };
        entry.value = val;
        this.$emit("input", entry);
      },
      get() {
        return this.value.value;
      }
    },
    iOffSet: {
      set(val) {
        let entry = { ...this.value };
        entry.offset = val;
        this.$emit("input", entry);
      },
      get() {
        return this.value.offset;
      }
    }
  }
};
</script>

<style scoped>
.limit-box {
  margin: 0;
}

.limit-box > label {
  margin-top: 5px;
  margin-bottom: 0;
}
input[type="number"] {
  opacity: 1;
}
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}

input[disabled] {
  background-color: #eee;
  opacity: 1;
}

.constants {
  width: 75px;
  position: relative;
}

.constants > input {
  display: inline-block;
  width: 100%;
  height: 28px !important;
  margin: 0;
  padding: 9px 0 0 0 !important;
  border: 0;
  outline: 0;
  text-align: center;
  background-color: transparent;
  font-size: 75%;
}
.constants > input[disabled] {
  background-color: #eee;
  opacity: 1;
}

.constants > .labels {
  position: absolute;
  top: 0;
  left: 2px;
  font-size: 65%;
  width: 100%;
  z-index: 3;
  background: transparent;
  text-align: left;
}

.constants > .labels > span {
  display: inline-block;
  width: 34px;
}
.label-addon.disabled {
  background-color: #eee;
}
.label-addon.disabled:hover {
  cursor: not-allowed;
  opacity: 0.8;
}
</style>
